// META BOXES

.meta-boxes {
  margin-top: -10px;
  margin-bottom: 10px;
  .inside-content {
    background-color: #283D53;
    padding: 12px;
    display: flex;
    align-items: center;
    max-height: 105px;
    min-height: 105px;
    margin-bottom: 20px;
    .block-img {
      margin: 0 20px 0 10px;
    }
    .block-text {
      h3,
      p {
        color: #fff;
      }
      h3 {
        font-size: 18px;
        text-transform: uppercase;
        display: flex;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (min-width: 760px) and (max-width: 1020px) {
      flex-direction: column;
      min-height: 200px;
      text-align: center;
      .block-img {
        margin: 5px 0;
      }
    }
  }
}

// BOX IMAGES

.box-images {
  margin-bottom: 50px;
  .line-effect {
    position: relative;
    a {
      position: relative;
      display: block;
      border: 1px solid solid rgb(0, 0, 0);
       
      img {
        max-width: 100%;
        display: inline;
        text-align: center;
      }
      &:before {
        border-bottom: 1px solid rgb(0, 0, 0);
        border-top: 1px solid rgb(0, 0, 0);
        transform: scale(0, 1);
      }
      &:after {
        border-left: 1px solid rgb(0, 0, 0);
        border-right: 1px solid rgb(0, 0, 0);
        transform: scale(1, 0);
      }
      &:hover:before,
      &:hover:after {
        opacity: 1;
        transform: scale(1);
      }
      &:before,
      &:after {
        bottom: 10px;
        content: "";
        left: 10px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 10px;
        transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
        z-index: 1;
      }
    }
    &:before {
      transform: none;
      display: none;
    }
    &::before {
      top:0;
      right:0;
      bottom:0;
      left:0;
      transform:none;
    }
    &:hover:before,
    &:hover:after {
      opacity: 0;
      transform: none;
    }
  }
  @media screen and (max-width: 1020px) {
    text-align: center;
    .line-effect {
      a {
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
}

// BOX CONTENT

.box-content {
  margin-bottom: 30px;
  background-image:  url('../image/banere/banner-bg-reduceri-luna.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 62px 0;
  .content {
    display: flex;
    h3, p, a {
      text-transform: uppercase;
    }
    .text {
      align-self: center;
      h3 {
        color: #233A53;
        font-size: 26px;
      }
      p {
        color: #233A53;
        font-size: 14px;
      }
    }
    .link {
      align-self: center;
      a {
        padding-bottom: 7px;
        border-bottom: 1px solid #233A53;
        font-size: 14px;
        color: #233A53;
      }
    }
  }
  @media screen and (max-width: 760px) {
    background-position: unset;
    .content {
      flex-direction: column;
      text-align: center;
    }
  }
}

//TESTIMONIALS

.testimonials {
  background-image: url('http://www.multipurposethemes.com/opencartdemo/oc013/image/catalog/Data/bg-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  .row {
    padding: 5% 0;
    display: flex;
    align-items: center;
    .info-text {
      text-align: center;
      h1, h2 {
        margin: 0;
        color: #fff;
        font-weight: bold;
      }
      h4 {
        font-size: 1em;
        font-weight: bold;
        color: #fff;
        margin-top: 20px;
        i {
          padding: 0 5px;
        }
      }
    }
    .testimonial {
      .carousel-inner {
        .item {
          h2, p {
            color: #fff;
          }
          p {
            padding-top: 25px;
            font-size: 14px;
          }
          h2 {
            font-size: 24px;
          }
          img {
            transform: scale(0.7);
            border: 10px solid #ccc;
            border-radius: 50%;
          }
        }
      }
      .carousel-control {
        background-image: none;
        width: 0;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .row {
      flex-direction: column;
      text-align: center;
      .carousel-control {
        display: none;
      }
    }
  }
}