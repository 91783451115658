@import "home/boxes";
@import "menu/primary-menu";
@import "products/products";
@import "general/general";

/* top */
#top {
	background-color: #293D52;
  padding: 5px 0;
  border: none;
  margin-bottom: 0;
  .top-text {
    font-size: 14px;
    color: #fff;
    a {
      text-transform: lowercase;
      text-shadow: none;
      color: #fff;
      transition: all .2s ease-in-out;
      &:hover {
        color: #eee;
      }
    }
  }
  @media screen and(max-width: 760px) {
    .container {
      display: flex;
      .top-text {
        order: 0;
        font-size: 12px;
      }
      .pull-right {
        order: 1;
      }
    }
  }
}
#top .container {
	padding: 0 20px;
}
#top #form-currency .currency-select,
#top #form-language .language-select {
  color: #000000;
	text-align: left;
}
#top #form-currency .currency-select:hover,
#top #form-language .language-select:hover {
	text-shadow: none;
	color: #ffffff;
	background-color: #F8584D;
	background-image: linear-gradient(to bottom, #F8584D, #F8584D);
	background-repeat: repeat-x;
}
#top .btn-link, #top-links li, #top-links a {
  color: #fff;
  text-transform: uppercase;
  text-shadow: none;
	text-decoration: none;
}
#top .btn-link:hover, #top-links a:hover {
	color: #eee;
}
#top-links .dropdown-menu a {
	text-shadow: none;
}
#top-links .dropdown-menu a:hover {
  color: #FFF;
}
#top .btn-link strong {
  line-height: 14px;
}
#top-links {
	padding-top: 6px;
}
#top-links a + a {
	margin-left: 15px;
}
.btn-group.open {
  .dropdown-menu {
    margin-top: 0;
    right: 0;
    left: initial;
    padding: 5px 0;
    button {
      padding: 2px 10px;
    }
  }
}

// header 

header {
  padding: 30px 0;
  background-color: #F7F7F7;
  .container {
    .row {
      display: flex;
      align-items: center;
      @media screen and (max-width: 760px) {
        display: block;
        #search {
          margin: 20px 0;
        }
      }
    }
  }
}
/* logo */
#logo {
  margin: 0 0 0 0;
  h1 {
    margin: 10px 0 0 0;
    a {
      text-transform: uppercase;
      font-size: 28px;
      color: #000000;
      font-weight: bold;
    }
  }
}

// slideshow
.swiper-viewport {
  margin-bottom: 80px !important;
  border: none !important;
}
.swiper-pagination {
  display: none !important;
}

/* search */
#search {
  margin-bottom: 10px;
  width: 100%;
  input {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #000000;
  }
  .input-group-btn {
    position: absolute;
    right: 20px;
    top: 12px;
    button {
      color: #000000;
      transform: scale(1.3);
      padding: 0 !important;
      background: transparent;
      border: none;
    }
  }
}
#search .input-lg {
	height: 45px;
	padding: 0 20px;
}
#search .btn-lg {
	font-size: 15px;
	line-height: 18px;
	padding: 10px 35px;
	text-shadow: 0 1px 0 #FFF;
}
/* cart */
#cart {
  float: right;
  width: auto;
  margin-bottom: 10px;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  .fa-shopping-cart:before {
    content: '\f290';
    font-size: 25px;
    margin-right: 10px;
  }
  span {
    font-size: 15px;
  }
}
#cart > .btn {
	font-size: 12px;
	line-height: 18px;
  color: #000;
  padding: 0;
  width: 100%;
}
#cart.open > .btn {
	background-image: none;
	background-color: transparent;
	border: none;
	color: #000;
	box-shadow: none;
	text-shadow: none;
}
#cart.open > .btn:hover {
	color: #000;
}
#cart .dropdown-menu {
  p {
    color: #000;
  }
  table, tr, td {
    border: none;
  }
  .table {
    .table-head {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F8584D;
      td {
        margin: 0 5px;
        padding: 5px 8px;
      }
      a,
      td {
        color: #000;
        font-size: 14px;
      }
      button {
        border-radius: 0;
        border: none;
        box-shadow: none;
        padding: 1px 5px;
      }
    }
  }
  background: #F8584D;
  width: 350px;
	z-index: 1001;
}
#cart .dropdown-menu {
	min-width: 100%;
}
@media (max-width: 478px) {
	#cart .dropdown-menu {
		width: 100%;
	}
}
#cart .dropdown-menu table {
	margin-bottom: 10px;
}
#cart .dropdown-menu li > div {
  color: #000;
  font-size: 14px;
	min-width: 340px;
	padding: 0 10px;
}
@media (max-width: 478px) {
	#cart .dropdown-menu li > div {
		min-width: 100%;
	}
}
#cart .dropdown-menu li p {
  margin: 20px 0;
  font-size: 14px;
  a {
    color: #000;
    i {
      color: #fff;
    }
  }
}
/* menu */
#menu {
	background-color: #F7F7F7;
	background-image: none;
	background-repeat: repeat-x;
	border-color: transparent;
  min-height: 40px;
  margin-bottom: 0;
  border-top: 1px solid rgba(#000, 0.1);
  border-bottom: 1px solid #000;
  padding: 5px 0;
}
#menu .nav > li > a {
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	padding: 10px 15px 10px 15px;
	min-height: 15px;
  background-color: transparent;
}
#menu .nav > li > a:hover, #menu .nav > li.open > a {
	background-color: transparent;
}
#menu .dropdown-menu {
	padding-bottom: 0;
}
#menu .dropdown-inner {
	display: table;
}
#menu .dropdown-inner ul {
	display: table-cell;
}
#menu .dropdown-inner a {
	min-width: 160px;
	display: block;
	padding: 3px 20px;
	clear: both;
	line-height: 20px;
	color: #333333;
	font-size: 12px;
}
#menu .dropdown-inner li a:hover {
	color: #FFFFFF;
}
#menu .see-all {
	display: block;
	margin-top: 0.5em;
	border-top: 1px solid #DDD;
	padding: 3px 20px;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 3px 3px;
	font-size: 12px;
}
#menu .see-all:hover, #menu .see-all:focus {
	text-decoration: none;
	color: #ffffff;
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
}
#menu #category {
	float: left;
	padding-left: 15px;
	font-size: 16px;
	font-weight: 700;
	line-height: 40px;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
#menu .btn-navbar {
	font-size: 15px;
	font-stretch: expanded;
	color: #FFF;
	padding: 2px 18px;
	float: right;
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
	border-color: #1f90bb #1f90bb #145e7a;
}
#menu .btn-navbar:hover, #menu .btn-navbar:focus, #menu .btn-navbar:active, #menu .btn-navbar.disabled, #menu .btn-navbar[disabled] {
	color: #ffffff;
	background-color: #229ac8;
}
@media (min-width: 768px) {
	#menu .dropdown:hover .dropdown-menu {
		display: block;
	}
}
@media (max-width: 767px) {
	#menu {
		border-radius: 4px;
	}
	#menu div.dropdown-inner > ul.list-unstyled {
		display: block;
	}
	#menu div.dropdown-menu {
		margin-left: 0 !important;
		padding-bottom: 10px;
		background-color: rgba(0, 0, 0, 0.1);
	}
	#menu .dropdown-inner {
		display: block;
	}
	#menu .dropdown-inner a {
		width: 100%;
		color: #fff;
	}
	#menu .dropdown-menu a:hover,
	#menu .dropdown-menu ul li a:hover {
		background: rgba(0, 0, 0, 0.1);
	}
	#menu .see-all {
		margin-top: 0;
		border: none;
		border-radius: 0;
		color: #fff;
	}
}
.swiper-paginationt {
  display: none;
}

//home

#common-home {
  .swiper-viewport {
    box-shadow: none;
    border-bottom: 1px solid rgba(#000, 0.3);
  }
  @media screen and (max-width: 760px) {
    .swiper-viewport {
      .swiper-pager {
        display: none;
      }
    }
  }
}
/* content */
// #content {
// 	min-height: 600px;
// }

//featured 

.featured-title,
.featured-subtitle {
  text-transform: uppercase;
}
.featured-title {
  color: #283D53;
}
.featured-subtitle {
  font-size: 14px;
}
.featured-line {
  margin-left: 0; 
  width: 30px;
  border-width: 3px;
  border-color: #283D53;
}

/* footer */
footer {
	margin-top: 0px;
	padding-top: 50px;
	background-color: #162331;
	border-top: none;
  color: #e2e2e2;
  li {
    transition: all .5s ease-in-out;
    &:hover {
      transform: translate(5px);
    }
  }
  p {
    margin: 0;
    padding: 25px 0;
    text-align: center;
    font-size: 14px;
    border-top: 1px solid rgba(#fff, 0.7);
    a {
      &:before {
        content: '';
        margin: 0;
      }
      &:visited {
        color: #ccc;
      }
    }
  }
  .row {
    margin-bottom: 20px;
  }
}
footer hr {
	border-top: none;
	border-bottom: 1px solid #666;
}
footer a {
  color: #ccc;
  font-size: 14px;
  transition: .5s ease-in-out;
  &::before {
    content: '\f068';
    font-family: fontawesome;
    margin-right: 10px;
    font-size: 7px;
    color: #f95647;
  }
}
footer a:hover {
  color: #fff;
}
footer h5 {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: bold;
  color: #fff;
  padding-bottom: 10px;
}
footer hr {
  color: #fff;
  width: 15%;
  margin: 0 0 20px 0;
  border-color: #fff;
  opacity: 0.7;
}
/* alert */
.alert {
  max-width: 1170px;
  margin: 0 auto;
	padding: 8px 14px 8px 14px;
}
/* breadcrumb */

#checkout-cart,
#checkout-checkout {
  h1,
  h2,
  p,
  label {
    color: #000;
  }
  label, p {
    font-size: 14px;
  }
  .form-group {
    a {
      font-size: 14px;
      color: #000;
    }
  }
  table, tr, td {
    color: #000;
    font-size: 14px;
    a {
      color: #000;
    }
  }
  .btn-primary {
    margin: 0 5px;
  }
  .breadcrumb {
    margin-top: 20px;
  }
}
.breadcrumb-bar {
  display: flex;
  align-items: center;
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
}

.breadcrumb-title {
  padding-left: 0;
  h3 {
    margin: 0;
    &:first-of-type {
      display: none;
    }
  }
}

.breadcrumb {
  font-size: 14px;
  // text-align: center;
  margin: 15px 0;
  background-color: #F8584D;
}
.breadcrumb i {
	font-size: 15px;
}
.breadcrumb > li {
	text-shadow: none;
  position: relative;
  padding: 0 0 0 5px;
  white-space: nowrap;
  a {
    color: #fff;
  }
}
.breadcrumb > li + li:before {
  padding: 0 10px 0 0;
  font-size: 16px;
  color: #fff;
	content: '»';
}

.pagination {
	margin: 0;
}


/* buttons */
.buttons {
	margin: 1em 0;
}
.btn {
	padding: 7.5px 12px;
	font-size: 12px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn-xs {
	font-size: 9px;
}
.btn-sm {
	font-size: 10.2px;
}
.btn-lg {
	padding: 10px 16px;
	font-size: 15px;
}
.btn-group > .btn, .btn-group > .dropdown-menu, .btn-group > .popover {
	font-size: 12px;
}
.btn-group > .btn-xs {
	font-size: 9px;
}
.btn-group > .btn-sm {
	font-size: 10.2px;
}
.btn-group > .btn-lg {
	font-size: 15px;
}
.btn-default {
	color: #777;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	background-color: #e7e7e7;
	background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
	background-repeat: repeat-x;
	border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
}
.btn-primary {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
	border-color: #1f90bb #1f90bb #145e7a;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
	background-color: #1f90bb;
	background-position: 0 -15px;
}
.btn-warning {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #faa732;
	background-image: linear-gradient(to bottom, #fbb450, #f89406);
	background-repeat: repeat-x;
	border-color: #f89406 #f89406 #ad6704;
}
.btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}
.btn-danger {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #da4f49;
	background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
	background-repeat: repeat-x;
	border-color: #bd362f #bd362f #802420;
}
.btn-danger:hover, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}
.btn-success {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #5bb75b;
	background-image: linear-gradient(to bottom, #62c462, #51a351);
	background-repeat: repeat-x;
	border-color: #51a351 #51a351 #387038;
}
.btn-success:hover, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}
.btn-info {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #df5c39;
	background-image: linear-gradient(to bottom, #e06342, #dc512c);
	background-repeat: repeat-x;
	border-color: #dc512c #dc512c #a2371a;
}
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
	background-image: none;
	background-color: #df5c39;
}
.btn-link {
	border-color: rgba(0, 0, 0, 0);
	cursor: pointer;
	color: #23A1D1;
	border-radius: 0;
}
.btn-link, .btn-link:active, .btn-link[disabled] {
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	box-shadow: none;
}
.btn-inverse {
  padding: 5px 10px;
	color: #000;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: transparent;
	background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-color: transparent;
  box-shadow: none;
  height: 50px;
  &:active {
    box-shadow: none;
  }
}
.btn-inverse:hover, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
	background-color: transparent;
	background-image: none;
}
/* list group */
.list-group a {
	border: 1px solid #DDDDDD;
	color: #888888;
	padding: 8px 12px;
}
.list-group a.active, .list-group a.active:hover, .list-group a:hover {
	color: #444444;
	background: #eeeeee;
	border: 1px solid #DDDDDD;
	text-shadow: 0 1px 0 #FFF;
}
/* @group carousel */
.carousel-caption {
	color: #FFFFFF;
	text-shadow: 0 1px 0 #000000;
}
.carousel-control .icon-prev:before {
	content: '\f053';
	font-family: FontAwesome;
}
.carousel-control .icon-next:before {
	content: '\f054';
	font-family: FontAwesome;
}
/* @end */

/* @group product list */

.product-layout {
  margin-bottom: 0px;
}

.product-thumb {
  margin-top: 0px;
  position: relative;
	border: none;
	margin-bottom: 20px;
  overflow: hidden;
  min-height: 446px;
  width: 100%;

  &:hover {
    .button-group {
      visibility: visible;
      opacity: 1;
    }
    .image {
      opacity: 0.5;
    }
  }

  .thumb-bg {
    display: block;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (max-width: 1200px) {
    margin: 20px auto;
  }
}
.product-thumb .image {
  transition: all .25s ease-in-out;
  text-align: center;
  // min-height: 73%;
}
.product-thumb .image a {
  display: block;
  transition: all .25s ease-in-out;
}
.product-thumb .image a:hover {
	opacity: 1;
}
.product-thumb .image img {
	margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.product-grid .product-thumb .image {
	float: none;
}
@media (min-width: 767px) {
.product-list .product-thumb .image {
	float: left;
	padding: 0 15px;
}
}
.product-thumb h4 {
	font-weight: bold;
}
.product-thumb .caption {
  text-align: center;
	padding-bottom: 10px;
  min-height: 27%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h4 {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    a {
      color: #000;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.product-list .product-thumb .caption {
	margin-left: 230px;
}
@media (max-width: 1200px) {
.product-grid .product-thumb .caption {
	min-height: 210px;
	padding: 0 10px;
}
}
@media (max-width: 767px) {
.product-list .product-thumb .caption {
	min-height: 0;
	margin-left: 0;
	padding: 0 10px;
}
.product-grid .product-thumb .caption {
	min-height: 0;
}
}
.product-thumb .rating {
  padding-bottom: 10px;
  &::before {
    opacity: 0.5;
    font-size: 16px;
    font-family: FontAwesome;
    content: "\f005";
  }
}
.rating .fa-stack {
	font-size: 8px;
}
.rating .fa-star-o {
	color: #999;
	font-size: 15px;
}
.rating .fa-star {
	color: #FC0;
	font-size: 15px;
}
.rating .fa-star + .fa-star-o {
	color: #E69500;
}
h2.price {
	margin: 0;
}
.product-thumb .price {
  font-style: italic;
  font-size: 17px;
	color: #000;
}
.product-thumb .price-new {
	font-weight: 600;
}
.product-thumb .price-old {
	color: #999;
	text-decoration: line-through;
	margin-left: 10px;
}
.product-thumb .price-tax {
	display: none;
}
.product-thumb .button-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  border-top: none;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
	background-color: #F8584D;
  overflow: auto;
  // width: 50px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease-in-out;
}
.product-list .product-thumb .button-group {
	border-left: none;
}
@media (max-width: 768px) {
	.product-list .product-thumb .button-group {
		border-left: none;
	}
}
.product-thumb .button-group button {
	width: auto;
	border: none;
	display: inline-block;
	float: left;
	background-color: transparent;
	color: #fff;
	line-height: 38px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
}
.product-thumb .button-group button + button {
	width: auto;
	border-left: none;
}
.product-thumb .button-group button:hover {
	color: #fff;
	background-color: transparent;
	text-decoration: none;
	cursor: pointer;
}
@media (max-width: 1200px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}
}
@media (max-width: 767px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}
}
.thumbnails {
	overflow: auto;
	clear: both;
	list-style: none;
	padding: 0;
  margin: 0;
  .thumbnail.active,
  .thumbnail:focus,
  .thumbnail:hover {
    border-color: #ddd;
  }
}
.thumbnails > li {
	margin-left: 20px;
}
.thumbnails {
	margin-left: -20px;
}
.thumbnails > img {
	width: 100%;
}
.image-additional a {
	margin-bottom: 20px;
	padding: 5px;
	display: block;
	border: 1px solid #ddd;
}
.image-additional {
	max-width: 78px;
}
.thumbnails .image-additional {
	float: left;
	margin-left: 20px;
}

@media (min-width: 1200px) {
	#content .col-lg-2:nth-child(6n+1),
	#content .col-lg-3:nth-child(4n+1),
	#content .col-lg-4:nth-child(3n+1),
	#content .col-lg-6:nth-child(2n+1) {
		clear:left;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	#content .col-md-2:nth-child(6n+1),
	#content .col-md-3:nth-child(4n+1),
	#content .col-md-4:nth-child(3n+1),
	#content .col-md-6:nth-child(2n+1) {
		clear:left;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	#content .col-sm-2:nth-child(6n+1),
	#content .col-sm-3:nth-child(4n+1),
	#content .col-sm-4:nth-child(3n+1),
	#content .col-sm-6:nth-child(2n+1) {
		clear:left;
	}
}

/* fixed colum left + content + right*/
@media (min-width: 768px) {
    #column-left  .product-layout .col-md-3 {
       width: 100%;
    }

	#column-left + #content .product-layout .col-md-3 {
       width: 50%;
    }

	#column-left + #content + #column-right .product-layout .col-md-3 {
       width: 100%;
    }

    #content + #column-right .product-layout .col-md-3 {
       width: 100%;
    }
}

/* fixed product layouts used in left and right columns */
#column-left .product-layout, #column-right .product-layout {
	width: 100%;
}

/* fixed mobile cart quantity input */
.input-group .form-control[name^=quantity] {
	min-width: 50px;
}

/* Missing focus and border color to overwrite bootstrap */
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
    background-image: none;
    background-color: #df5c39;
}
