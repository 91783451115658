#column-left {
  z-index: 0;
}

.navigation-bar {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  a {
    color: #000;
    transition: all .25s ease-in-out;
    &:focus {
      text-decoration: none;
      outline: none;
    }
    &:hover {
      color: lighten(#000, 35%);
    }
  }
  .parent {
    cursor: pointer;
    position: relative;
    padding: 15px 12px;
    font-size: 14px;
    text-transform: uppercase;

    .parent-submenu {
      width: 700px;
      position: absolute;
      top: 50px;
      padding: 30px;
      background-color: #fff;
      box-shadow: 0 0 20px 0 #ccc;
      list-style-type: none;
      display: none;
      z-index: 99999;
      
      &.large-submenu {
        width: 950px;
      }
      &>li {
        a {
          padding-bottom: 5px;
          font-weight: bold;
          border-bottom: 1px solid #aaa;
        }
        &>ul {
          margin-bottom: 30px;
          margin-top: 20px;
          padding: 0;
          list-style-type: none;
          font-weight: normal;
          text-transform: initial;
          a {
            font-weight: normal;
            border: none;
            text-transform: capitalize;
          }
          li {
            padding: 1px 0;
            transition: all .25s ease-in-out;
            &:hover {
              transform: translate(3px);
            }
            .glyphicon {
              transform: scale(0.65)
            }
          }
        }
      }
    }
    .rightpos {
      right: 0;
    }
    &.has-submenu {
      .glyphicon {
        padding-left: 3px;
        transform: scale(0.6);
      }
    }
    .text-above {
      position: absolute;
      top: -18px;
      right: 30px;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      background-color: #000;
      padding: 2px 8px;
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        right: 5px;
        width: 15px;
        height: 10px;
        background-color: #000;
        clip-path: polygon(40% 0, 45% 100%, 100% 0);
      }
    }
    &:hover {
      .text-above {
        color: #000;
        background-color: #F7F7F7;
        &:before {
          display: none;
        }
      }
    }
  }

  .menu-image {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    right: 0;
    width: 460px;
  }
  .mi1 {
    top: auto;
    width: 442px;
    height: 208px;
    background-image: url(../image/menu/extensii-par-natural.jpg);
  }
  .mi2 {
    background-image: url(../image/menu/black-edition.jpg);
  }
  .mi3 {
    background-image: url(../image/menu/extensii-sahara.jpg);
  }
  .mi4 {
    background-image: url(../image/menu/sahara-deluxe.jpg);
  }
  .mi5 {
    background-image: url(../image/menu/sahara-diamond.jpg);
  }
  .mi6 {
    background-image: url(../image/menu/cozi-de-par.jpg);
  }
  .mi7 {
    background-image: url(../image/menu/peruci.jpg);
  }
  .rightpos {
    .menu-image {
      right: auto;
      left: 0;
    }
  }

  //MOBILE SCREEN

  @media screen and(max-width: 760px) {
    flex-direction: column;
    .text-above {
      display: none;
    }
  }

  //TABLET SCREEN

}

@media screen and (max-width: 760px) {
  #menu {
    .navbar-header {
      .btn-navbar {
        padding: 5px 15px;
        border: 1px solid #000;
        box-shadow: none;
        background-image: none;
        background-color: #F7F7F7 !important;
        color: #000 !important;
        i {
          font-size: 24px;
        }
      }
    }
  }
}