#content {
  margin-bottom: 50px;
  .list-unstyled {
    li {
      line-height: 25px;
      font-size: 14px;
      color: #000;
      h2 {
        font-weight: bold;
        color: #000;
      }
      a {
        color: #000;
      }
    }
  }
  .rating {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 10px;
    p {
      margin: 0;
      a {
        color: #000;
        font-size: 14px;
      }
      span {
        &:last-of-type {
          margin-right: 10px;
        }
      }
    }
  }
  .site-infos {
    padding-left: 65px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .block-img {
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding-bottom: 30px;
    }
    .block-text {
      width: 100%;
      color: #000;
      text-align: left;
      font-size: 14px;
      border-bottom: 1px solid #ccc;
      padding: 20px 0 20px 10px;
    }
    .meta-info-box {
      padding: 10px 0;
      margin-top: 20px;
      background-color: #F8584D;
      text-align: center;     
      display: flex;
      align-items: center;
      h4, p {
        text-transform: uppercase;
        color: #fff;
      }
      h4 {
        font-weight: bold;
      }
      i {
        padding-left: 15px;
        color: #fff;
        font-size: 30px;
      }
    }
  }
  #product {
    .form-group {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      margin-top: 15px;
      .quantity-input {
        display: flex;
        align-items: center;
        label {
          font-size: 14px;
          margin-right: 10px;
          color: #000;
        }
        input {
          width: 100px;
          border-radius: 0;
        }
      }
      .buttons {
        margin-bottom: 0;
        display: flex;
        .btn-group {
          display: flex;
          #button-wishlist {
            margin-left: 10px;
            margin-right: 10px;
          }
          #button-wishlist,
          #button-compare {
            padding: 13px 15px;
            border: 1px solid #000;
            border-radius: 0;
            background: none;
            color: #000;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.comments-reviews {
  margin-bottom: 50px;
  .nav-tabs {
    li {
      margin-right: 30px;
    }
    a {
      font-size: 14px;
      color: #000;
      &:hover {
        border: 1px solid #ddc;
      }
    }
    .active {
      a {
        color: #000;
        background-color: #ddd;
        &:hover {
          color: #000;
          background-color: #ddd;
        }
        &:focus {
          color: #000;
          background-color: #ddd;
        }
      }
    }
  }
  .tab-content {
    font-size: 14px;
    color: #000;
    label {
      font-size: 14px;
    }
  }
}

.related-products {
  h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 32px;
    color: #000;
    font-weight: 600;
  }
}