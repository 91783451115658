body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #666;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
  color: #444;
}
/* default font size */
.fa {
  font-size: 14px;
}
/* Override the bootstrap defaults */
h1 {
  font-size: 33px;
}
h2 {
  font-size: 27px;

  &.page-title {
    margin-bottom: 40px;
    font-weight: bold;
  }
}
h3 {
  font-size: 21px;
}
h4 {
  font-size: 15px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10.2px;
}
a {
  color: #23a1d1;
}
a:hover {
  text-decoration: none;
}
legend {
  font-size: 18px;
  padding: 7px 0px
}
label {
  font-size: 12px;
  font-weight: normal;
}
select.form-control, textarea.form-control, input[type="text"].form-control, input[type="password"].form-control, input[type="datetime"].form-control, input[type="datetime-local"].form-control, input[type="date"].form-control, input[type="month"].form-control, input[type="time"].form-control, input[type="week"].form-control, input[type="number"].form-control, input[type="email"].form-control, input[type="url"].form-control, input[type="search"].form-control, input[type="tel"].form-control, input[type="color"].form-control {
  font-size: 12px;
}
.input-group input, .input-group select, .input-group .dropdown-menu, .input-group .popover {
  font-size: 12px;
}
.input-group .input-group-addon {
  font-size: 12px;
  height: 30px;
}
/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
  display: inline;
}

.nav-tabs {
  margin-bottom: 15px;
}
div.required .control-label:before {
  content: '* ';
  color: #F00;
  font-weight: bold;
}
/* Gradent to all drop down menus */
.dropdown-menu li > a:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: #229ac8;
  background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
  background-repeat: repeat-x;
}
.btn.btn-primary,
.btn.btn-danger {
  color: #fff;
  background-image: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 14px;
  text-shadow: none;
  border: none;
  &:hover {
    background-image: none;
  }
}

.btn.btn-primary {
  background-color: #000;
  &:hover {
    background-color: #000;
  }
}

.btn.btn-danger {
  background-color: #C73534;
  &:hover {
    background-color: #B3302F;
  }
}
.img-responsive {
  margin: 0 auto;
}